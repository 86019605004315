define("ember-cp-validations/validators/alias", ["exports", "@ember/debug", "@ember/utils", "@ember/object", "ember-cp-validations/validators/base"], function (_exports, _debug, _utils, _object, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Creates an alias between a single attribute's validations to another.
   *  This copies all messages, errors, etc., to the current attribute as well as
   *  its validation state (isValid, isValidating, etc.)
   *
   *  ## Examples
   *
   *  ```javascript
   *  validator('alias', 'attribute')
   *  validator('alias', {
   *    alias: 'attribute',
   *    firstMessageOnly: true
   *  })
   *  ```
   *
   *  @class Alias
   *  @module Validators
   *  @extends Base
   */
  const Alias = _base.default.extend({
    /**
     * Normalized options passed in.
     * ```js
     * validator('alias', 'attribute')
     * // Becomes
     * validator('alias', {
     *   alias: 'attribute'
     * })
     * ```
     *
     * @method buildOptions
     * @param  {Object}     options
     * @param  {Object}     defaultOptions
     * @param  {Object}     globalOptions
     * @return {Object}
     */
    buildOptions() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let defaultOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let globalOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let opts = options;
      if (typeof options === 'string') {
        opts = {
          alias: options
        };
      }
      return this._super(opts, defaultOptions, globalOptions);
    },
    /**
     * @method validate
     * @param {Any} value
     * @param {Object} options
     * @param {String} options.alias The attribute to alias
     * @param {Boolean} options.firstMessageOnly If true, only returns the first error message of the
     *                                           aliased attribute and will not include validation state
     * @param {Object} model
     * @param {String} attribute
     */
    validate(value, options, model, attribute) {
      let {
        alias,
        firstMessageOnly
      } = options;
      (true && !((0, _utils.isPresent)(alias)) && (0, _debug.assert)(`[validator:alias] [${attribute}] option 'alias' is required`, (0, _utils.isPresent)(alias)));
      let aliasValidation = (0, _object.get)(model, `validations.attrs.${alias}`);
      return firstMessageOnly ? aliasValidation.message : aliasValidation.content;
    }
  });
  Alias.reopenClass({
    getDependentsFor(attribute, options) {
      let alias = typeof options === 'string' ? options : options.alias;
      (true && !(typeof alias === 'string') && (0, _debug.assert)(`[validator:alias] [${attribute}] 'alias' must be a string`, typeof alias === 'string'));
      return [`${alias}.messages.[]`, `${alias}.isTruelyValid`];
    }
  });
  var _default = Alias;
  _exports.default = _default;
});