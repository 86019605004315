define("ember-validators/index", ["exports", "@embroider/macros/es-compat", "@ember/debug", "@ember/utils"], function (_exports, _esCompat, _debug, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validate = validate;
  function validate(type) {
    let validator;
    if (type === 'collection') {
      validator = import_collection();
    } else if (type === 'confirmation') {
      validator = import_confirmation();
    } else if (type === 'date') {
      validator = import_date();
    } else if (type === 'ds-error') {
      validator = import_ds_error();
    } else if (type === 'exclusion') {
      validator = import_exclusion();
    } else if (type === 'format') {
      validator = import_format();
    } else if (type === 'inclusion') {
      validator = import_inclusion();
    } else if (type === 'length') {
      validator = import_length();
    } else if (type === 'messages') {
      validator = import_messages();
    } else if (type === 'number') {
      validator = import_number();
    } else if (type === 'presence') {
      validator = import_presence();
    }
    (true && !((0, _utils.isPresent)(validator)) && (0, _debug.assert)(`Validator not found of type: ${type}.`, (0, _utils.isPresent)(validator)));
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    return validator.default(...args);
  }
  function import_collection() {
    return (0, _esCompat.default)(require("ember-validators/collection"));
  }
  function import_confirmation() {
    return (0, _esCompat.default)(require("ember-validators/confirmation"));
  }
  function import_date() {
    return (0, _esCompat.default)(require("ember-validators/date"));
  }
  function import_ds_error() {
    return (0, _esCompat.default)(require("ember-validators/ds-error"));
  }
  function import_exclusion() {
    return (0, _esCompat.default)(require("ember-validators/exclusion"));
  }
  function import_format() {
    return (0, _esCompat.default)(require("ember-validators/format"));
  }
  function import_inclusion() {
    return (0, _esCompat.default)(require("ember-validators/inclusion"));
  }
  function import_length() {
    return (0, _esCompat.default)(require("ember-validators/length"));
  }
  function import_messages() {
    return (0, _esCompat.default)(require("ember-validators/messages"));
  }
  function import_number() {
    return (0, _esCompat.default)(require("ember-validators/number"));
  }
  function import_presence() {
    return (0, _esCompat.default)(require("ember-validators/presence"));
  }
});